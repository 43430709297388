<template>
  <div id="app">
    <div class="tabs">
      <!--<button :class="{ active: currentTab === 'chat' }" @click="currentTab = 'chat'">Chat</button>
      <button :class="{ active: currentTab === 'rpg' }" @click="currentTab = 'rpg'">Battle</button>-->
    </div>
    <div class="tab-content">
      <chat-comp v-if="currentTab === 'chat'" />
      <rpg-comp v-if="currentTab === 'rpg'" />
      <date-comp v-if="currentTab === 'date'" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import chatComp from "./components/chatComp.vue";
import rpgComp from "./components/rpgComp.vue";
import dateComp from "./components/dateComp.vue";

export default {
  name: 'App',
  components: {
    chatComp,
    rpgComp,
    dateComp
  },
  data() {
    return {
      currentTab: 'date'
    };
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.tabs {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: #1abc9c;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tabs button.active {
  background-color: #16a085;
}

.tabs button:hover {
  background-color: #16a085;
}

.tab-content {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #1abc9c;
  border-radius: 5px;
}
</style>
