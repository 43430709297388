<template>
  <div class="chat-container">
    <div class="message-container">
      <ul>
        <li v-for="(msg, index) in lastMessages" :key="index">
          <span class="user-name">{{ msg.user }}</span>: 
          <span class="message" v-html="formatMessage(msg.message)"></span>
        </li>
      </ul>
    </div>
    <div class="input-container">
      <textarea v-model="message" placeholder="Message" class="text-input" @keyup="onMessageKeyUp" />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import * as signalR from "@microsoft/signalr";

export default {
  data() {
    return {
      user: "Asuna",
      message: "",
      messages: [],
      connection: null,
    };
  },
  computed: {
    lastMessages() {
      return this.messages.slice(-25);
    },
  },
  mounted() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://kotakeikaku.com/chatHub", {
        accessTokenFactory: () => {},
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    this.connection.on("ReceiveMessage", (user, message) => {
      this.messages.push({ user, message });
    });

    this.connection
      .start({
        xdomain: true,
      })
      .catch((err) => console.error(err.toString()));
  },
  methods: {
    sendMessage() {
      if (this.user && this.message) {
        this.connection
          .invoke("SendMessage", this.user, this.message)
          .catch((err) => console.error(err.toString()));
        this.message = ""; // Clear the message input after sending
      }
    },
    onMessageKeyUp(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        this.sendMessage();
      }
    },
    formatMessage(message) {
      return message.replace(/\n/g, '<br/>');
    }
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  height: 95vh;
  font-family: 'Courier New', Courier, monospace;
  background-color: #2c3e50;
  color: #ecf0f1;
}

.message-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #7f8c8d;
  text-align: left;
}

.message-container ul {
  list-style: none;
  padding: 0;
}

.message-container li {
  margin: 5px 0;
}

.input-container {
  display: flex;
  padding: 10px;
  background-color: #34495e;
  height: 5%;
}

.input-container input {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  border: none;
  border-radius: 3px;
  background-color: #7f8c8d;
  color: #2c3e50;
}

.input-container button {
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background-color: #1abc9c;
  color: #2c3e50;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #16a085;
}

.user-name {
  font-weight: bold;
  color: #1abc9c;
}
.message {

}
.text-input {
  line-height: 1.42857;
  min-height: initial !important;
  max-height: 250px;
  resize: none;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #4c4c98;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: auto;
}
</style>