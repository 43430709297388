<template>
  <div class="battle-container">
    <div class="opponent">
      <div class="sprite">Opponent Sprite</div>
    </div>
    <div class="bottom-panel">
      <div class="stats">
        <div class="stat">HP: {{ hp }}</div>
        <div class="stat">MP: {{ mp }}</div>
      </div>
      <div class="actions">
        <button class="margin-right" @click="sendCommand('attack')">Attack</button>
        <button @click="sendCommand('special')">Special</button>
        <button class="margin-right" @click="sendCommand('item')">Item</button>
        <button @click="sendCommand('wait')">Wait</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as signalR from "@microsoft/signalr";

export default {
  data() {
    return {
      user: "",
      hp: 100, // Example initial HP
      mp: 50,  // Example initial MP
      commands: [],
      connection: null,
    };
  },
  mounted() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://kotakeikaku.com/rpgHub", {
        accessTokenFactory: () => {},
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    this.connection.on("ReceiveCommand", (response) => {
      this.commands.push(response);
    });

    this.connection
      .start({
        xdomain: true,
      })
      .catch((err) => console.error(err.toString()));
  },
  methods: {
    sendCommand(command) {
      this.connection
        .invoke("SendCommand", this.user, command)
        .catch((err) => console.error(err.toString()));
    },
  },
};
</script>

<style scoped>
.battle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  font-family: 'Courier New', Courier, monospace;
  background-color: #2c3e50;
  color: #ecf0f1;
}

.opponent {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.sprite {
  width: 150px;
  height: 150px;
  background-color: #7f8c8d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.bottom-panel {
  flex: 1;
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.stats {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat {
  font-size: 18px;
  margin: 5px 0;
}

.actions {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;
}

.actions button {
  padding: 10px 20px;
  border: none;
  background-color: #1abc9c;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left:50%;
  width:50%;
}

.actions button:hover {
  background-color: #16a085;
}

.margin-right {
  transform:translateX(80%);
}
</style>
