<template>
  <div
    class="date-container"
    @click="continueDialog"
    @keyup.space="continueDialog"
  >
    <div id="img" v-html="backgroundHtml"></div>
    <div class="character-sprite" v-if="characterHtml && characterHtml.length > 0" v-html="characterHtml"></div>
    <div class="dialog-box" ref="dialogBox">
      <div class="dialog-text">{{ hyphenatedDialog }}</div>
    </div>
    <div class="choices" v-if="choices.length && isLastChunk">
      <button v-for="choice in choices" :key="choice" @click="sendChoice(choice)">{{ choice }}</button>
    </div>
  </div>
  <div id="text-measurer" ref="textMeasurer" class="hidden-text">{{ textForMeasurement }}</div>
</template>

<script>
import * as signalR from "@microsoft/signalr";

export default {
  data() {
    return {
      fullDialog: "Tap to start the game!", // Complete dialog text received from the server
      dialogIndex: 0, // Current index in the dialog text
      dialogChunkSize: 260, // Initial number of characters to display at a time
      choices: [], // Example initial choices
      backgroundHtml: "", // Example initial background image
      characterHtml: "",
      connection: null,
      isLastChunk: false, // Flag to check if the current chunk is the last chunk
      textForMeasurement: "", // Text used for measuring
      canAdvance: true, // Flag to control text advancement
      advanceDelay: 250, // Delay in milliseconds
    };
  },
  computed: {
    hyphenatedDialog() {
      return this.hyphenateText(this.fullDialog.slice(this.dialogIndex, this.dialogIndex + this.dialogChunkSize));
    },
  },
  watch: {
    hyphenatedDialog(newVal) {
      this.textForMeasurement = newVal;
      this.$nextTick(() => {
        this.adjustDialogChunkSize();
      });
    },
  },
  async mounted() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://kotakeikaku.com/rpgHub", {
        accessTokenFactory: () => {}, // Provide a function to get the access token if required
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    this.connection.on("ReceiveDialog", (response) => {
      console.log("received", response);
      this.dialogIndex = 0; // Reset the index for new dialog
      this.fullDialog = response.dialog;
      this.choices = response.choices || [];
      if (response.backgroundImage) {
        this.backgroundHtml = `<img src="${response.backgroundImage}" />`;
      }
      if (response.characterImage) {
        this.characterHtml = `<img src="${response.characterImage}" />`;
      }
      else if (this.characterHtml && this.characterHtml.length > 0 && (!response.characterImage || response.characterImage.length == 0)) {
        this.characterHtml = "";
      }
      this.isLastChunk = this.fullDialog.length <= this.dialogChunkSize;
    });

    try {
      await this.connection.start();
      console.log("SignalR connected");
    } catch (err) {
      console.error("SignalR connection error: ", err.toString());
    }

    // To allow keyboard event listening
    window.addEventListener('keyup', this.continueDialog);
    window.addEventListener('resize', this.onWindowResize);

    // Initial resize adjustment
    this.onWindowResize();
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.continueDialog);
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      const size = window.innerWidth;
      this.dialogChunkSize = Math.floor(259.0 * (size / 1920.0));
      this.adjustDialogChunkSize();
      this.updateImageSize();
    },
    async adjustDialogChunkSize() {
      this.textForMeasurement = this.fullDialog.slice(this.dialogIndex, this.dialogIndex + this.dialogChunkSize);
      await this.$nextTick(); // Wait for the DOM to update
    },
    updateImageSize() {
      const img = document.querySelector('#img img');
      if (img) {
        img.style.width = `${window.innerWidth}px`;
      }
    },
    continueDialog() {
      if (this.canAdvance) {
        this.canAdvance = false;
        setTimeout(() => {
          this.canAdvance = true;
        }, this.advanceDelay);

        if (!this.choices.length && !this.isLastChunk) {
          this.connection
            .invoke("ContinueDialog", "")
            .catch((err) => console.error(err.toString()));
        } else if (this.isLastChunk && this.choices.length) {
          // Do nothing if it's the last chunk and choices are available
        } else {
          this.dialogIndex += this.dialogChunkSize;
          this.isLastChunk = this.dialogIndex + this.dialogChunkSize >= this.fullDialog.length;
        }
      }
    },
    sendChoice(choice) {
      this.connection
        .invoke("ContinueDialog", choice)
        .catch((err) => console.error(err.toString()));
    },
    hyphenateText(text) {
      if (text.length < this.dialogChunkSize) {
        return text;
      }
      const lastSpaceIndex = text.lastIndexOf(' ');
      if (lastSpaceIndex === -1 || lastSpaceIndex >= this.dialogChunkSize - 1) {
        return text;
      }
      return text; //text.slice(0, lastSpaceIndex) + '-' + text.slice(lastSpaceIndex + 1);
    }
  },
};
</script>

<style scoped>
#img img {
  width: 100%; /* Adjust the image width to fit the inner window size */
  height: auto; /* Maintain aspect ratio */
  display: block;
}

.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  font-family: 'Courier New', Courier, monospace;
  background-color: #2c3e50;
  color: #ecf0f1;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden; /* Ensure content is contained */
}

.character-sprite {
  width: 150px;
  height: 150px;
  background-color: #7f8c8d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
}

.dialog-box {
  flex: 1;
  display: flex;
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: absolute;
  bottom: 20px; /* Adjust position as needed */
}

.dialog-text {
  font-size: 18px;
  color: #ecf0f1;
}

.choices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  background-color: black;
  transform: translateY(-5%);
  opacity: 95%;
}

.choices button {
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  background-color: #1abc9c;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.choices button:hover {
  background-color: #16a085;
}

.hidden-text {
  visibility: hidden;
  position: absolute;
  white-space: normal;
  width: 80%;
  padding: 20px;
  font-size: 18px;
  line-height: 1.5;
  background-color: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
}
</style>